div.not_found_message {
  font-size: 2rem;
  margin-bottom: 2rem;
}

img.crying_bee {
  width: 391px;
  height: 421px;
  opacity: 0.8;
}
