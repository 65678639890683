.AssetBrowser__SpritePreview {
  min-width: 128px;
  min-height: 128px;
  image-rendering: pixelated;
  object-fit: contain;
  border: dashed 2px #f17575;
  margin: 10px;
}

@media (max-width: 768px) {
  .AssetBrowser__SpritePreview {
    min-width: 96px;
    min-height: 96px;
  }
}
@media (max-width: 576px) {
  .AssetBrowser__SpritePreview {
    min-width: 64px;
    min-height: 64px;
  }
}

.AssetBrowser__AssetResult {
  margin: 5px;
}

.AssetBrowser__AssetResult__Name {
  text-align: center;
}

.AssetBrowser__AssetResult__Preview {
  align-items: center;
  justify-content: center;
  display: flex;
  background: repeating-conic-gradient(#6a6a6a 0% 25%, #5b5b5b 0% 50%) 50% /
    20px 20px;
  border-radius: 5px;
}

a.repo-path-link {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.AssetBrowser__AssetResult__Details {
  overflow: hidden;
  text-overflow: ellipsis;
}
