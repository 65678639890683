.LatestRounds__ServerRow {
  display: flex;
  justify-content: space-between;
}

.LatestRounds__ServersHeader {
  border-bottom: 1px #dee2e6 solid;
  padding-bottom: 10px;
}

.LatestRounds__ServerRow__Dotter {
  flex-grow: 1;
  min-width: 10px;
  //border-bottom: 1px dotted #dee2e6;
}
